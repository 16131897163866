import React, { useEffect, useReducer, useRef } from 'react'

import { colors } from '../../constants/colors'
import {
  ButtonPaddingSize,
  ButtonPreset,
  ButtonTextColor,
} from '../../elements/Button/Button'
import { TypeProps } from '../../elements/Typography/Typography'
import { ScreenTheme } from '../../styles/types'
import { VisuallyHidden } from '../../units/VisuallyHidden'
import { BaseButton, HoverButton } from '../Button/styles'
import * as styles from './styles'
import { useLoadingProgress } from './useLoadingProgress'

enum ActionType {
  SET_LOADING,
  SET_COMPLETED,
}

type ReducerAction =
  | {
      type: ActionType.SET_COMPLETED
      complete: boolean
    }
  | {
      type: ActionType.SET_LOADING
      loading: boolean
    }

interface ReducerState {
  loading: boolean
  complete: boolean
}

const setLoading = (loading: boolean): ReducerAction => ({
  type: ActionType.SET_LOADING,
  loading,
})
const setComplete = (complete: boolean): ReducerAction => ({
  type: ActionType.SET_COMPLETED,
  complete,
})

const reducer = (state: ReducerState, action: ReducerAction) => {
  switch (action.type) {
    case ActionType.SET_LOADING:
      return {
        ...state,
        loading: action.loading,
      }
    case ActionType.SET_COMPLETED:
      return {
        complete: action.complete,
        loading: false,
      }
  }
}

export interface ProgressButtonProps
  extends Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'onClick'> {
  loading?: boolean
  complete?: boolean
  onClick?: () => void | Promise<boolean> | Promise<void>
  onComplete?: () => void
  preset?: ButtonPreset
  paddingSize?: ButtonPaddingSize
  noHover?: boolean
  size?: TypeProps['size']
  weight?: TypeProps['weight']
  textColor?: ButtonTextColor

  // The `display` property is passed to BaseButton via `...rest` but does not seem to be used.
  //
  // We are keeping this property here since it's provided by two components within `product-flow`.
  display?: string
  minWidth?: number

  // These are passed via `...rest` to the BaseButton to control the margins at
  // various different breakpoints.
  //
  // I'm choosing not to type these properly right now. When Button.js is converted
  // to typescript the Props should be updated to extend the BaseButton props:
  //
  //    interface Props extends BaseButtonProps, React.ButtonHTMLAttributes<HTMLButtonElement> {
  //      // ...
  //    }
  //
  top?: unknown
  bottom?: unknown
  left?: unknown
  right?: unknown
}

export const themeToPresetsMap: Partial<Record<ScreenTheme, ButtonPreset>> = {
  default: 'blue',
  blue: 'lightGrey',
  highlandDefault: 'highlandGreen',
  highlandGreen: 'highlandWhite',
  highlandMyBooking: 'black',
}

export const ProgressButton: React.FC<ProgressButtonProps> = ({
  loading,
  complete,
  onClick,
  onComplete,
  preset = 'blue',
  noHover,
  size = { xs: 14, md: 16 },
  weight = 'bold',
  children,
  paddingSize = 'medium',
  textColor,
  ...rest
}) => {
  const [state, dispatch] = useReducer(reducer, {
    loading: loading || false,
    complete: complete || false,
  })

  const handleMouseMove = (event: React.MouseEvent) => {
    const target = event.target as HTMLElement
    target.style.setProperty('--x', `${event.nativeEvent.offsetX}px`)
    target.style.setProperty('--y', `${event.nativeEvent.offsetY}px`)
  }

  // In Simple form we use onSubmit from the form and control the animation from the form component
  const controlled = complete !== undefined && loading !== undefined

  // for controlled
  useEffect(() => {
    if (!controlled) {
      return
    }

    if (loading !== state.loading) {
      dispatch(setLoading(!!loading))
    }
    if (complete !== state.complete) {
      dispatch(setComplete(!!complete))
    }
  }, [loading, complete])

  const handleClick = async () => {
    // we don't need the onClick handler for form submit buttons
    if (controlled || state.loading || state.complete) {
      return
    }

    dispatch(setLoading(true))

    try {
      const shouldContinue = onClick ? await onClick() : false

      if (!shouldContinue) {
        dispatch(setLoading(false))
        return
      }

      dispatch(setComplete(true))
      setTimeout(onComplete, 300)
    } catch {
      dispatch(setLoading(false))
    }
  }

  const Component = noHover ? BaseButton : HoverButton

  const progressRef = useRef<HTMLElement>(null)

  useLoadingProgress(state, t => {
    const el = progressRef.current
    if (!el) {
      return
    }
    el.style.transform = `translateX(-${(1 - t) * 100}%)`
  })

  return (
    <Component
      onClick={handleClick}
      onMouseMove={!noHover ? handleMouseMove : undefined}
      preset={preset}
      paddingSize={paddingSize}
      disabled={loading || !!rest.disabled}
      {...rest}
    >
      <styles.Content>
        <VisuallyHidden aria-hidden={!loading}>Loading</VisuallyHidden>
        {
          <styles.Text
            size={size}
            weight={weight}
            aria-hidden={loading}
            style={{ color: textColor ? colors[textColor] : 'inherit' }}
          >
            {children}
          </styles.Text>
        }
      </styles.Content>
      <styles.ProgressInner ref={progressRef} />
    </Component>
  )
}
